@import url('@sikt/sds-badge');
@import url('@sikt/sds-breadcrumbs');
@import url('@sikt/sds-button');
@import url('@sikt/sds-checkbox');
@import url('@sikt/sds-combobox');
@import url('@sikt/sds-core');
@import url('@sikt/sds-drawer');
@import url('@sikt/sds-footer');
@import url('@sikt/sds-form');
@import url('@sikt/sds-header');
@import url('@sikt/sds-icons');
@import url('@sikt/sds-input-datepicker');
@import url('@sikt/sds-input-file');
@import url('@sikt/sds-input');
@import url('@sikt/sds-logo');
@import url('@sikt/sds-message');
@import url('@sikt/sds-dialog');
@import url('@sikt/sds-pagination');
@import url('@sikt/sds-radio');
@import url('@sikt/sds-select');
@import url('@sikt/sds-table');
@import url('@sikt/sds-tabs');
@import url('@sikt/sds-toggle');
@import url('@sikt/sds-details');

:root {
  /* TODO: Remove when implemented in designsystem */
  --fsa-space-padding-medium1: var(--sds-base-size-m1);

  @media (width <= 1024px) {
    --fsa-space-padding-medium1: var(--sds-base-size-m);
  }

  @media (width <= 720px) {
    --fsa-space-padding-medium1: var(--sds-base-size-s1);
  }
}
